<template>
  <div
    class="flex w-full min-w-[288px] max-w-[376px] flex-col shadow-2xl justify-center space-y-4 p-4 pb-6 md:p-8 text-sm bg-base-100 rounded-lg border border-base-300"
    :class="[componentName]"
  >
    <div class="flex flex-col gap-4">
      <h3
        v-if="props.title"
        class="text-xl mb-2 md:text-2xl text-center font-medium text-base-content"
        v-html="props.title"
      />
      <SignInWithMagicUriForm v-if="component === 'SignInWithMagicUriForm'" />
      <EmailSuccess v-if="component === 'EmailSuccess'" />
    </div>

    <SignInProviderDivider ref="referenceButton" />

    <div class="flex flex-col gap-4 content-start text-base-300">
      <div
        v-if="true"
        ref="buttonContainer"
        class="flex w-full overflow-hidden min-w-[258px] items-center justify-center rounded min-h-[40px]"
      ></div>

      <button
        class="flex w-full gap-2 justify-between items-center px-3 py-2 rounded text-base-500 border border-base-300 hover:border-base-400 hover:bg-base-200/30 hover:text-base-content"
        :class="inProgress ? 'opacity-30 cursor-progress' : undefined"
        :disabled="inProgress"
        @click="signInWith(EAuthenticationProviders.LINKEDIN)"
      >
        <img
          loading="lazy"
          src="/assets/icons/linkedin-icon.png"
          alt=""
          class="shrink-0 self-start w-6 aspect-square"
        />
        <span>{{ linkedInSignInText }}</span>
        <span></span>
      </button>

      <button
        class="flex w-full gap-2 justify-between items-center px-3 py-2 rounded text-base-500 border border-base-300 hover:border-base-400 hover:bg-base-200/30 hover:text-base-content"
        :class="inProgress ? 'opacity-30 cursor-progress' : undefined"
        :disabled="inProgress"
        @click="
          signInWith(EAuthenticationProviders.X, {
            stage: EXSignInStages.REQUEST_REDIRECT,
          })
        "
      >
        <XLogo class="w-[18px] h-[18px]" />
        <span>{{ xSignInText }}</span>
        <span></span>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import SignInWithMagicUriForm from '@/domain/Authentication/components/SignInWithEmailForm.vue'
import EmailSuccess from '@/domain/Authentication/components/EmailSuccess.vue'

export const componentName = 'SignInCard'
export default defineComponent({
  name: componentName,
  components: {
    SignInWithMagicUriForm,
    EmailSuccess,
  },
})
</script>

<script setup lang="ts">
import { computed } from 'vue'
import SignInProviderDivider from '@/domain/Authentication/components/SignInProviderDivider.vue'
import { useAuthentication } from '@/domain/Authentication/composables/useAuthentication'
import { EAuthenticationProviders } from '@/domain/Authentication/contracts/EAuthenticationProviders'
import { EAuthenticationProviderSignInFlowStates } from '@/domain/Authentication/contracts/EAuthenticationProviderSignInFlowStates'
import { EXSignInStages } from '@/domain/Authentication/contracts/EXSignInStages'
import XLogo from '@/domain/Authentication/components/XLogo.vue'
import { useGoogleSignButton } from '@/domain/Authentication/composables/useGoogleSignButton'

const props = withDefaults(defineProps<{ title?: string }>(), {
  title: undefined,
})

const { signInWith, status, selectedProvider } = useAuthentication()

const component = computed<string>(() =>
  status.value === EAuthenticationProviderSignInFlowStates.SUCCEEDED &&
  selectedProvider.value === EAuthenticationProviders.MAGIC_SIGN_IN
    ? 'EmailSuccess'
    : 'SignInWithMagicUriForm',
)
const linkedInSignInText = 'Sign in with LinkedIn'
const xSignInText = 'Sign in with X'

const inProgress = computed(() => {
  return (
    status.value === EAuthenticationProviderSignInFlowStates.STARTED &&
    [
      EAuthenticationProviders.LINKEDIN,
      EAuthenticationProviders.GOOGLE,
      EAuthenticationProviders.X,
    ].includes(selectedProvider.value)
  )
})

const { referenceButton, buttonContainer } = useGoogleSignButton()
</script>
