<template>
  <FlexibleSectionLayout
    v-if="authorsComputed.length > 0"
    tag="section"
    :visualize-width="false"
    width="narrow"
    :class="componentName"
    :container-classes="containerClasses"
    :content-container-classes="contentContainer"
  >
    <TextHeading
      :title-tag="EHeadline.H2"
      :content="{
        title: props.title,
        subline: props.subline,
      }"
      :classes="{
        container: titleContainer,
        title: title,
        subline: subline,
      }"
    />
    <hr :class="divider" />
    <ul :class="authorsContainer">
      <AuthorInformation
        v-for="(author, index) in authorsComputed"
        :key="author?.id ?? index"
        tag="li"
        :name="author.name"
        :description="author.description"
        :role="author.role"
        :image="author.image"
        :is-the-only-author="authorsComputed.length !== 1"
        :references="author.references"
        :classes="{
          authorName: props.classes?.authorName,
          avatar: props.classes?.avatar,
          referenceIcon: props.classes?.referenceIcon,
          role: props.classes?.role,
          description: props.classes?.description,
        }"
      />
    </ul>
  </FlexibleSectionLayout>
</template>

<script lang="ts">
export const componentName = 'AuthorSection'
</script>

<script lang="ts" setup>
import { computed, defineOptions } from 'vue'
import { cva } from 'class-variance-authority'
import FlexibleSectionLayout from '@/domain/Content/components/FlexibleSectionLayout.vue'
import TextHeading from '@/domain/Content/components/TextHeading.vue'
import { EHeadline } from '@/domain/Content/contracts/EHeadline'
import { twMerge } from 'tailwind-merge'
import AuthorInformation from '@/domain/Content/components/AuthorInformation.vue'
import type { TAuthorModel } from '@/domain/Book/contracts/TAuthorModel'
import { useOnePageEntryWithOptions } from '@/app/composables/useOnePageEntry'
import type { TUsePageContentProps } from '@/domain/Book/contracts/TUsePageContentProps'
import { toAuthors } from '@/domain/Book/support/toAuthors'

defineOptions({
  name: componentName,
})

const props = withDefaults(
  defineProps<
    {
      classes?: {
        container?: string
        contentContainer?: string
        titleContainer?: string
        title?: string
        subline?: string
        divider?: string
        authorsContainer?: string
        authorName?: string
        avatar?: string
        referenceIcon?: string
        role?: string
        description?: string
      }
      title?: string
      subline?: string
      authors?: TAuthorModel[]
    } & TUsePageContentProps & { attributes?: any } // eslint-disable-next-line @typescript-eslint/no-explicit-any
  >(),
  {
    classes: () => ({
      container: undefined,
      contentContainer: undefined,
      titleContainer: undefined,
      title: undefined,
      subline: undefined,
      divider: undefined,
      authorsContainer: undefined,
      authorName: undefined,
      avatar: undefined,
      referenceIcon: undefined,
      role: undefined,
      description: undefined,
    }),
    usePageContent: false,
    pageContentModel: 'page',
    authors: () => [],
  },
)

const containerClasses = computed(() =>
  twMerge(cva('w-full my-6')({ class: props.classes.container })),
)

const contentContainer = computed(() =>
  twMerge(cva('flex-col')({ class: props.classes.contentContainer })),
)

const titleContainer = computed(() =>
  twMerge(
    cva('w-full flex flex-col sm:flex-row justify-between items-start sm:items-end')({
      class: props.classes.titleContainer,
    }),
  ),
)

const title = computed(() =>
  twMerge(cva('text-2xl !mt-2')({ class: props.classes.title })),
)

const subline = computed(() =>
  twMerge(
    cva('flex flex-row gap-2 items-center text-base text-base-500 font-medium')({
      class: props.classes.title,
    }),
  ),
)

const divider = computed(() =>
  twMerge(cva('w-full mt-2 mb-6')({ class: props.classes.divider })),
)

const authorsContainer = computed(() =>
  twMerge(
    cva('grid gap-x-6 gap-y-2 w-full', {
      variants: {
        onlyOneAuthor: {
          true: 'grid-cols-1',
          false: 'grid-cols-1 lg:grid-cols-2',
        },
      },
    })({
      onlyOneAuthor: authorsComputed.value.length === 1,
      class: props.classes.authorsContainer,
    }),
  ),
)

const { content: page, doFetch } = useOnePageEntryWithOptions(props.pageContentModel, {
  useAutoFetch: false,
})

if (props.usePageContent) {
  doFetch({})
}

const authorsComputed = computed(() => {
  if (props.usePageContent) {
    return toAuthors(page)
  }

  return props.authors ? props.authors : []
})
</script>

<style scoped></style>
